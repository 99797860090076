// import Cookie from './class/cookie.class'
import OnReady from './class/onready.class'
import OnReadyEvents from './class/onreadyevents.class'
// import * as HammerTime from './lib/hammer-time.min'
import * as Modernizr from './lib/modernizr'

"use strict";

$(function () {
    const $body = $('body');
    const $header = $('#header');
    const $nav = $('#nav')
    const $window = $(window)
    let winW = $window.width()

    $window.on('load', () => {

        
    })
    
    $(document).on('ready', () => {
        $('#loader').fadeOut();
        $body.addClass('loading-finished')
    
        /**
         * Animation fondus blocs
         */
    
        let $onload_items = $('.onload-ft');
    
        // Onload items
        $.each($onload_items, function() {
            let $this = $(this);
            let delay = +$this.data('onload-delay')
    
            TweenMax.from($this, .75, {
                opacity: 0,
                y: '+=20',
                delay: delay,
                ease: Power3.easeOut,
                clearProps: "all",
                onComplete: () => {
                    $window.trigger('resize');
                }
            })
        });
    
        setTimeout(() => {
             // Gestion avancée des ancres
            var hash = window.location.hash;
            let hashTarget = document.getElementById(hash.substr(1));
            if (hashTarget) {
                
                const $hashTarget = $(hashTarget);
                let navH = $nav.outerHeight();
                let hashTargetPaddingTop = parseInt($hashTarget.css('padding-top'), 10);
                let hashTargetOffsetTop = $hashTarget.offset().top;
    
                let scrollToY = hashTargetOffsetTop;
                
                // if ($body.hasClass('nav_scrolled')) {
                    scrollToY -= navH;
                // }
                
                // Gestion padding top element
                scrollToY -= (20 - hashTargetPaddingTop);
                                
                // onload animation
                scrollToY -= 20
    
                // console.log('hashTarget', hashTarget)
                // console.log('hashTargetPaddingTop', hashTargetPaddingTop)
                // console.log('hashTargetOffsetTop', hashTargetOffsetTop)                
                // console.log('navH', navH)
                // console.log('scrollToY', scrollToY)
    
                window.scrollTo(0, scrollToY);
    
            }
        }, 200);
        OnReady();
        OnReadyEvents();

        // new WOW({
        //     mobile: false
        // }).init()

        // $(".animsition").animsition({
        //     inClass: 'fade-in',
        //     outClass: 'fade-out',
        //     inDuration: 350,
        //     outDuration: 250,
        //     linkElement: 'a:not([target="_blank"]):not([href^="#"]):not(.link-product):not(.remove):not([class^="shutterset"]):not(.ngg-fancybox):not(#fancybox-close):not(.mfp-link)',
        //     // e.g. linkElement: 'a:not([target="_blank"]):not([href^=#])'
        //     loading: true,
        //     loadingParentElement: 'html', //animsition wrapper element
        //     loadingClass: 'animsition-custom',
        //     timeout: false,
        //     timeoutCountdown: 1500,
        //     onLoadEvent: true,
        //     browser: ['animation-duration', '-webkit-animation-duration'],
        //     // "browser" option allows you to disable the "animsition" in case the css property in the array is not supported by your browser.
        //     // The default setting is to disable the "animsition" in a browser that does not support "animation-duration".
        //     overlay: false,
        //     overlayClass: 'animsition-overlay-slide',
        //     overlayParentElement: 'body',
        //     transition: function (url) {
        //         window.location.href = url;
        //     }
        //   });
    })

    

});



