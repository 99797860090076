import soLoadAsync from './async.class'

export default function OnReady() {
    const $html = $('html')      
    const $body = $('body')      
    const $window = $(window)      
    const winW = $window.width()
    const winWref = 1440;
    const URLParams = getURLParameters();    
    const isEN = $body.hasClass('lang-en');

    if (is_touch_device()) {
        $body.addClass('touch');  
    } else {
        $body.addClass('no-touch');
    }  

    // Formattage tourisme adapté tooltip
    const $divTourismeAdapteTooltip = $('#tourisme-adapte-tooltip');
    if ($divTourismeAdapteTooltip.length > 0) removeFirstLastBR($divTourismeAdapteTooltip.get(0));

    
    // Home slider
    const $homeSlider = $('#home-slider');
    if ($homeSlider.length > 0) {

        if (!$body.hasClass('ie8-max')) {
        
            $homeSlider.slick({
                autoplay: true,
                autoplaySpeed: 8000,

                lazyLoad: 'progressive',
                dots: true,
                arrows: false,
                infinite: true,
                speed: 450,
                slidesToShow: 1,
                slidesToScroll: 1,
                fade: false,

                // responsive: [
                //     {
                //       breakpoint: 768,
                //       settings: {
                //         arrows: true,
                //         dots: false,
                //       }
                //     },
                // ]
            });

        } else {

            const $dots = $('.slick-dots button');
            let activeSlideIndex = 0;

            function switchSlide(index) {
                activeSlideIndex = index;
                let target = '#home-slide-'+index;
                let $target = $(target);
                let $btn = $('[data-slide-index="'+index+'"]');

                document.querySelector('.slide.active').classList.remove('active');
                document.querySelector('.slick-dots .slick-active').classList.remove('slick-active');

                $target.addClass('active');
                $btn.parent().addClass('slick-active');
            }

            function autoSlide() {
                let nextSlideIndex = activeSlideIndex + 1;
                let slidesLength = parseInt(document.getElementById('home-slider').getAttribute('data-slides-length'));

                if (activeSlideIndex === slidesLength - 1) {
                    nextSlideIndex = 0;
                }
                
                switchSlide(nextSlideIndex);
            }

            let autoInterval = setInterval(autoSlide, 8000);

            $dots.on('click', function(e) {
                e.preventDefault();
                let $this = $(this);
                let index = parseInt($this.attr('data-slide-index'));

                clearInterval(autoInterval);
                autoInterval = setInterval(autoSlide, 8000);

                switchSlide(index);
            });

        }
    }


    // Items slider
    const $itemsSlider = $('.js-items-slider');
    if ($itemsSlider.length > 0) {

        if (!$body.hasClass('ie8-max')) {

            const $sliderContainer = $itemsSlider.find('> div');        
            $sliderContainer.slick({
                autoplay: true,
                autoplaySpeed: 8000,

                slides: '.list-block-item',

                lazyLoad: 'progressive',
                dots: false,
                arrows: true,
                infinite: true,
                speed: 450,
                slidesToShow: 3,
                slidesToScroll: 1,
                fade: false,

                prevArrow: '<button type="button" class="slick-prev h-o"></button>',
                nextArrow: '<button type="button" class="slick-next h-o"></button>',

                responsive: [
                    {
                      breakpoint: 900,
                      settings: {
                        slidesToShow: 2,
                      }
                    },
                    {
                        breakpoint: 640,
                        settings: {
                          slidesToShow: 1,
                        }
                    },
                ]
            });

        }
    }

    // Diaporama slider
    const $diaporamaSlider = $('.js-diaporama-slider');
    if ($diaporamaSlider.length > 0) {

        if ($diaporamaSlider.find('img').length > 0) {
            
            if (!$body.hasClass('ie8-max')) {
            
                $diaporamaSlider.slick({
                    autoplay: true,
                    autoplaySpeed: 8000,
                    
                    lazyLoad: 'progressive',
                    dots: false,
                    arrows: true,
                    infinite: true,
                    speed: 450,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    fade: false,
    
                    prevArrow: '<button type="button" class="slick-prev h-o"></button>',
                    nextArrow: '<button type="button" class="slick-next h-o"></button>',
                });
    
            }
            
        } else {

            const placeholderFicheSRC = '/wp-content/themes/palais-megeve/dist/images/placeholder-fiche.png';

            const slide = document.createElement('div');
            slide.classList.add('diaporama-slide');

            const slideImg = document.createElement('div');
            slideImg.classList.add('diaporama-slider__img', 'img-empty');

            const img = document.createElement('img');
            img.setAttribute('src', placeholderFicheSRC)

            slideImg.append(img);
            slide.append(slideImg);

            $diaporamaSlider.append(slide);
        }

    }


    // SVG Plus Append
    const svgPlusHTML = $('#template-svg-plus').html()
    const $linkPlus = $('.link-plus');
    $linkPlus.append(svgPlusHTML);

    if ($body.hasClass('ie-with-edge')) {
        $(document).on('mouseenter', '.hover-triggerer', function(e) {

            let $this = $(this);
            let $crosses = $this.find('.link-plus__cross-part');
            if ($crosses.length > 0) {

                TweenMax.staggerTo($crosses, .45, {
                    attr: {
                        transform: "rotate(180, 25, 25)"
                    },
                    ease: Power3.easeOut,
                }, 0.1)

            }

        }).on('mouseleave', '.hover-triggerer', function(e) {

            let $this = $(this);
            let $crosses = $this.find('.link-plus__cross-part');
            if ($crosses.length > 0) {

                TweenMax.staggerTo($crosses, .45, {
                    attr: {
                        transform: "rotate(0, 25, 25)"
                    },
                    ease: Power3.easeOut,
                }, 0.1)

            }

        })
    }
    

    // SVG Icons Append
    $('.utils-item__icon').each(function(index, elem) {
        const $elem = $(elem);
        const icon = $elem.attr('data-icon');
        const html = $('#template-svg-icon-util-'+icon).html();

        $elem.append(html);
    });

    // Pronoms titres wrappés avec span
    if (filter_keywords.length > 0) {
        const $filterPronoms = $('.js-filter-pronoms');
        $filterPronoms.each(function(index, elem) {
            const $elem = $(elem);
            let html = $elem.html();

            // html = html.replace(
            //     /(\b(Le|le|La|la|Nos|nos|Nous|nous|L'|l'|Du|du|Au|au|Foire|foire|Aux|aux|Inscription|inscription|Descriptif|complexe|un|Un|complexe)\b)+/g, 
            //     '<span>$1</span>'
            // );

            //var rg = new RegExp('(\\b(' + filter_keywords.join('|') + ')\\b)+', 'g');
            var rg = new RegExp('(\\b(' + filter_keywords.join('|') + ')\\b)+(?!.*-)', 'g');
            html = html.replace(
                rg, 
                '<span>$1</span>'
            );

            // Match DD/MM/YY && DD/MM/YYYY
            html = html.replace(
                /(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.]((?:\d{2}){1,2})/g, 
                '<span class="f-bold">$1/$2/$3</span>'
            );

            $elem.html(html);
        })
    }

    let datePickers = {};

    // DatePicker
    const nextYearDate = new Date(new Date().setFullYear(new Date().getFullYear() + 2));
    const pickadateMaxDate = false;

    // DatePicker Default settings
    let soObjPickadate = {
        format: 'mm/dd/yy',
        formatSubmit: 'yyyy-mm-dd',

        min: true,      // Minimum set to today
        max: pickadateMaxDate,         // limit in theory
        hiddenName: true,

        // selectYears: true,
        // selectMonths: true,

        onStart: function() {
            const _this = this;
            const value = this.get('value');

            // Timeout pour attendre l'initialisation de l'objet global datePickers{}
            setTimeout(function() {
                if (value) _this.set('select', value, { format: 'yyyy-mm-dd'});
            }, 200);
        },
        onRender: function() {
            $(this.$root).find('select').select2({
                dropdownAutoWidth : true,
                minimumResultsForSearch: Infinity,
            });
        },
        onSet: function(thingSet) {
            if (thingSet.select) {
                const minFor = this.$node.attr('data-min-for');
                const maxFor = this.$node.attr('data-max-for');
                const newDate = new Date(thingSet.select);
                
                if (minFor) datePickers[minFor].set('min', newDate, {muted: true});
                if (maxFor) datePickers[maxFor].set('max', newDate, { muted: true });
            }

            if (thingSet.clear === null) {
                const minFor = this.$node.attr('data-min-for');
                const maxFor = this.$node.attr('data-max-for');
                
                if (minFor) datePickers[minFor].set('min', true, {muted: true});
                if (maxFor) datePickers[maxFor].set('max', pickadateMaxDate, { muted: true });
            }
        },
    }


    if (!isEN) {
        soObjPickadate.monthsFull = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'];
        soObjPickadate.weekdaysShort = ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'];
        soObjPickadate.today = 'aujourd\'hui';
        soObjPickadate.clear = 'effacer';
        soObjPickadate.close = 'fermer';
        soObjPickadate.format = 'dd/mm/yy';

        // Accessibility labels
        soObjPickadate.labelMonthNext = 'Mois suivant';
        soObjPickadate.labelMonthPrev = 'Mois précédent';
        soObjPickadate.labelMonthSelect = 'Sélectionner un mois';
        soObjPickadate.labelYearSelect = 'Sélectionner une année';
    }    


    $window.load(function() {
        
        // Select2
        soLoadAsync("select2", function() {

            $('select').select2({

                dropdownAutoWidth : true,
                minimumResultsForSearch: Infinity,
                width: '100%'
    
            }).on('select2:open', function(e) {
    
                const $selectDropdown = $('body').find('> .select2-container');
    
                TweenMax.set($selectDropdown, {
                    zIndex: 10
                });
                TweenMax.fromTo($selectDropdown, .4, 
                    {
                        autoAlpha: 0,
                        y: '-=10',
                        ease: Power3.easeOut
                    }, {
                        autoAlpha: 1,
                        y: 0,
                        ease: Power3.easeOut
                    }
                )
                
            }).on('select2:unselecting', function() {
                var opts = $(this).data('select2').options;
                opts.set('disabled', true);
                setTimeout(function() {
                    opts.set('disabled', false);
                }, 1);
            });

            // Pickadate
            soLoadAsync("pickadate", function() {
                $.extend($.fn.pickadate.defaults, soObjPickadate);

                $('.js-pickadate').each(function(index, elem) {
                    const $elem = $(elem);
                    const $elemID = $elem.attr('id');
                    let $container = $elem.siblings('.field-datepicker-container');
                    const GETValue = getURLParameters($elemID);
            
                    if ($body.hasClass('mobile')) $container = $elem.parents('.moteur-wrapper');
            
                    if (GETValue && GETValue !== "undefined") $elem.val(GETValue);
            
                    if ($container.length > 0) {
                        $elem.pickadate({
                            container: $container
                        })
                    } else {
                        $elem.pickadate();
                    }
            
                    datePickers[$elemID] = $elem.pickadate('picker');
                });    
            })
            
        })   
        
        // Tippy
        soLoadAsync("tippy", function() {
            // Tooltips
            const $jsTippy = $('.js-tippy');
            const $jsTippyIfOverflow = $('.js-tippy-if-overflow');
            let tippyConfig = {
                arrow: true,
                arrowType: 'large',
                // duration: [0, 100000],   // slow for debugging
                placement: 'top',
                size: 'large',
                zIndex: 98,
                popperOptions: {
                    modifiers: {
                        computeStyle: {

                        }
                    }
                }
            }

            if ($html.hasClass('no-csstransforms3d')) {
                tippyConfig.popperOptions.modifiers.computeStyle.gpuAcceleration = false;
                tippyConfig.maxWidth = '400px';
            }

            if ($body.hasClass('touch')) {
                tippyConfig.trigger = 'click';
                tippyConfig.interactive = 'true';
            }

            $.each($jsTippy, function(index, elem) {
                const $elem = $(elem);
                config = tippyConfig;

                if ($elem.hasClass('js-tippy--content-focused')) {

                    if ($html.hasClass('no-csstransforms3d')) {
                        config.appendTo = elem.parentNode.parentNode;
                    }

                    $elem.click(function(event) {
                        event.preventDefault ? event.preventDefault() : event.returnValue = false;
                    })

                    config = Object.assign(tippyConfig, {
                        distance: 25,
                        interactive: true,
                        flipBehavior: function(winW) {
                            if ( window.innerWidth <= 768)
                                return "flip"

                            return ['right', 'bottom'];
                        }(),
                        placement: function(winW) {
                            if ( window.innerWidth <= 768)
                                return "top"

                            return "right";
                        }(),
                    })
                }

                tippy(elem, config);
            });

            $.each($jsTippyIfOverflow, function(index, elem) {
                const $elem = $(elem);
                config = tippyConfig;

                if (isOverflowed(elem)) {
                    const tippyHTML = elem.getAttribute("data-tippy-titlehtml");

                    if (tippyHTML.includes('mailto')) {
                        elem.lastChild.textContent = 'email';
                    } else if (tippyHTML.includes('http')) {
                        elem.lastChild.textContent = 'site internet';
                    }

                    if ($html.hasClass('no-csstransforms3d')) {
                        config.appendTo = elem.parentNode.parentNode;
                    }

                    const template = document.createElement('div')
                    template.innerHTML = tippyHTML;
                    config.html = template;

                    if ($elem.hasClass('js-tippy--content-focused')) {

                        $elem.click(function(event) {
                            event.preventDefault ? event.preventDefault() : event.returnValue = false;
                        })

                        config = Object.assign(tippyConfig, {
                            distance: 25,
                            interactive: true,
                            placement: function(winW) {
                                if ( window.innerWidth <= 768)
                                    return "top"

                                return "right";
                            }(),
                        })
                    }

                    tippy(elem, config);

                }
            });
        })
    });


    // Gestion images liste vide
    const $imgListItemWithoutSRC = $('.list-block-item, .agenda-list-item').find('img[src=""]');
    const placeholderListItemSRC = '/wp-content/themes/palais-megeve/dist/images/placeholder-list-item.png';
    $imgListItemWithoutSRC.attr('src', placeholderListItemSRC);
    $imgListItemWithoutSRC.parent().addClass('img-empty')

    // Gestion GET moteur APIDAE
    const GETgratuit = getURLParameters('gratuit');
    const GEThandicap = getURLParameters('handicap');
    const GETsearch = getURLParameters('sq');
    const GETsearch_decoded = decodeURIComponent(escape(GETsearch));

    if (GETgratuit === "on") $('input[name="gratuit"]').prop('checked', true);
    if (GEThandicap === "on") $('input[name="handicap"]').prop('checked', true);
    if (GETsearch && GETsearch !== "undefined" && GETsearch !== "") $('input[name="sq"]').val(GETsearch_decoded);


    // Form activités moteur
    $('form.moteur-wrapper').attr('action', config.pageurl);
    $('#reset-filters').attr('href', config.pageurl);

    // Formattage blocks tarifs, suppression du premier br
    const $tarifsBlock = $('.block-section--tarifs');
    if ($tarifsBlock.length > 0) {
        const $p = $tarifsBlock.find('p');

        if ($p.length > 0) {
            removeFirstLastBR($p.get(0));
        }
    }

    // Insertion bandeau dans template APIDAE
    const $bandeauItem = $('#bandeau-club-item');
    const $bandeauBlock = $('#bandeau-block');

    if ($bandeauBlock.length > 0) {
        $bandeauItem.appendTo($bandeauBlock);
    }


    // Rellax Parallax
    let rellaxSpeed = 12;
    let rellaxSpeedRatio = Math.max(1, (winW / winWref).toFixed(2));
    rellaxSpeed = rellaxSpeed * rellaxSpeedRatio;

    const rellaxClass = '.rellax';
    const $rellaxItems = $(rellaxClass);

    if (!$body.hasClass('mobile') && !$body.hasClass('ie8-max') && $rellaxItems.length > 0){


        soLoadAsync("rellax", function() {

             // Also can pass in optional settings block
            var rellax = new Rellax('.rellax', {
                speed: rellaxSpeed,
                center: false,
                round: true,
                vertical: true,
                horizontal: false
            })

        })

       

    }

    //Change amount according to
    

    
    // Functions
    function is_touch_device() {
        return 'ontouchstart' in window        // works on most browsers 
            || navigator.maxTouchPoints;       // works on IE10/11 and Surface
    }

    function isString (obj) {
        return (Object.prototype.toString.call(obj) === '[object String]');
    }
    
    function getURLParameters(paramName) {
        var sURL = window.document.URL.toString();
        if (sURL.indexOf("?") > 0)
        {
            var arrParams = sURL.split("?");
            var arrURLParams = arrParams[1].split("&");
            var arrParamNames = new Array(arrURLParams.length);
            var arrParamValues = new Array(arrURLParams.length);
            var objectsParam = {};
    
            var i = 0;
            for (i = 0; i<arrURLParams.length; i++)
            {
                var sParam =  arrURLParams[i].split("=");
                arrParamNames[i] = sParam[0];
                objectsParam[sParam[0]] = sParam[1];
                if (sParam[1] != "")
                    arrParamValues[i] = unescape(sParam[1]);
                else
                    arrParamValues[i] = false
            }
    
            for (i=0; i<arrURLParams.length; i++)
            {
                if (arrParamNames[i] == paramName)
                {
                    //alert("Parameter:" + arrParamValues[i]);
                    return arrParamValues[i];
                }
            }

            if (paramName) {
                return false;
            }
            
            return objectsParam;
        }
    }

    function isOverflowed(element){
        return element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth;
    }

    function removeFirstLastBR(element) {
        // if (typeof element !== 'object') return;
        const firstChild = element.firstChild;
        const lastChild = element.lastChild;

        if (firstChild !== undefined && firstChild.tagName === "BR") firstChild.remove()        
        if (lastChild !== undefined && lastChild.tagName === "BR") lastChild.remove()
    }


}