import _ from './../lib/underscore'

export default function OnReadyEvents() {
	const $html = $('html');
	const $window = $(window);
	const $body = $('body')
	const $nav = $('#nav')
	const $header = $('#header')
	const $footer = $('#footer')
	const isMobile = ($body.hasClass('mobile'))
	
	const fontSizeRef = 10;
	const winWref = 1440;

	let winW = $window.width()
	let scroll_top;
	let nav_h;
	let nav_top = $nav.offset().top;

	const svgPlusHTML = $('#template-svg-plus').html()

	// Test via a getter in the options object to see if the passive property is accessed
	var supportsPassive = false;
	try {
		var opts = Object.defineProperty({}, 'passive', {
			get: function() {
			supportsPassive = true;
			}
		});
		window.addEventListener("testPassive", null, opts);
		window.removeEventListener("testPassive", null, opts);
	} catch (e) {}


	// désactive le click sur les menus parents vides
	$(document).on('click', '.menu-item-has-children > a[href="#"]', function(e) {
		e.preventDefault();
	})

	$(document).on('click','.js-class-toggler', function(e) {
		const $this = $(this);
		const classToToggle = $this.attr('data-class-to-toggle');
		
		e.preventDefault();
		$this.toggleClass('is-active');
		$body.toggleClass(classToToggle);

		if (classToToggle === "show-nav oh") {
			$html.toggleClass('oh');
		}
	});

	$(document).on('click','.js-scroll-to', function(e) {
		const $this = $(this);
		const $target = $($this.attr('href'));
		
		e.preventDefault();
		$('html, body').animate({
			scrollTop: $target.position().top
		}, 500)
	});

	$(document).on('click','.js-collapser', function(e) {
		const $this = $(this);
		const target = $this.attr('data-collapser-target');
		const $target = $(target);
		
		e.preventDefault();
		
		const TL_Show = new TimelineMax({ paused: true });
		const TL_Hide = new TimelineMax({ paused: true });

		TL_Show
		.to($target, .4, {
			'maxHeight': $target[0].scrollHeight,
			ease: Power3.easeOut
		})
		.to($target, .35, {
			autoAlpha: 1,
			y: 0,
			ease: Power3.easeOut
		})

		TL_Hide
		.to($target, .2, {
			autoAlpha: 0,
			ease: Power3.easeOut
		})
		.to($target, .3, {
			'maxHeight': 0,
			y: 10,
			ease: Power3.easeOut
		})


		if (!$target.hasClass('is-active')) {
			TL_Show.play(0);
		} else {
			TL_Hide.play(0);
		}


		$this.toggleClass('is-active');
		$target.toggleClass('is-active');
	});


	$(document).on('click', '.menu-item.has-child', function(e) {
		e.preventDefault();
	});

	$(document).ready(() => {
		adapt_fontsize();
		$window.trigger('resize');
	});

	$window.load(() => {
		$window.trigger('resize');
	})

	// Gestion nav
	if ( !$body.hasClass('nav_scrolled') ) {
		var throttledScroll = _.throttle(adapt_scroll, 120);
		
		$window.on('scroll', () => {
			throttledScroll()
			// toggle_gotop_btn();
		})
		
		$window.load(() => {
			nav_top = $nav.offset().top;
			adapt_nav();
		});
	} else {
		$body.css('padding-top', $header.outerHeight());
	}

	const $pagination = $('.adtlistpage');
	if ($pagination.length > 0) {

		
		
		// window.scrollTo(0,0);
		let currentPage = parseInt($('#so-current-page').val(), 10);
		let infiniteLoading = false;
		let noMoreInfiniteLoading = false;

		console.log('currentPage', currentPage);
		const $apidaeList = $('#wp84apidae-list');
		const $infiniteScrollTriggerer = $('#infinite-scroll-triggerer');		
		const $infiniteScrollLoader = $('#infinite-scroll-loader');
		const maxNumber = parseInt( $pagination.find('li').last().text(), 10 );		

		function paginationLoading() {
			infiniteLoading = true;
			$infiniteScrollLoader.fadeIn(200);
		}

		function paginationLoaded() {
			infiniteLoading = false;
			$infiniteScrollLoader.fadeOut(200);
		}
		
		var throttledInView = _.throttle(function() {
			var shouldGetNew = $infiniteScrollTriggerer.isInViewport() && !infiniteLoading && !noMoreInfiniteLoading;

			if (shouldGetNew) {
				const nextPage = config.pageurl + "page/" + (currentPage + 1) + "/" + window.location.search;
				paginationLoading();

				$.ajax({
					url: nextPage,
					type: 'GET',
					success: function(data) {
						const $data = $(data);
						let $listItems = $data.find('#wp84apidae-list')
						let newItems = $listItems[0].children;
						$(newItems).find('.link-plus').append(svgPlusHTML);
						currentPage++;
			
						$apidaeList.append(newItems);
						if ( currentPage == maxNumber) noMoreInfiniteLoading = true;
						paginationLoaded()
					},
					error: function(data) {
						console.log('ERROR: ', data);
					}
				})
			}

		}, 500);

		$window.on('scroll', throttledInView);
	}	
	
	var debouncedResize = _.debounce(adapt_global, 500);
	var debouncedFontSize = _.debounce(adapt_fontsize, 500);

	$window.on('scroll', () => {
		scroll_top = $window.scrollTop();
	})
	.on('resize', () => {
		winW = $window.width();
		debouncedResize()
	})


	function adapt_global() {
		if (!isMobile)  {
			adapt_nav();
			adapt_fontsize();
		}
	}

	function adapt_scroll() {
		adapt_nav();
	}

	function adapt_nav() {				
		if ( (scroll_top >= nav_top) ) {

			if (!$body.hasClass('nav_scrolled')) {
				$body.css('padding-top', $header.outerHeight());
				$body.addClass('nav_scrolled');
			}
		} else {
			if ($body.hasClass('nav_scrolled')) {
				$body.removeClass('nav_scrolled');
				$body.css('padding-top', '');
			}
		}
	}



	function adapt_fontsize() {

		if (winW > winWref) {
			
			let ratio = winW / winWref;
			let newFontSize = fontSizeRef * ratio;

			// newFontSize = Math.floor(newFontSize * 10) / 10; // suits better but Firefox sub pixel rendering fails
			newFontSize = Math.floor(newFontSize * 2) / 2;
			// newFontSize = Math.floor(newFontSize);

			$html.css('font-size', newFontSize + 'px');
			
		} else {

			$html.css('font-size', '');
			
		}

		if (Modernizr.flexbox && Modernizr.flexwrap) {
			// Modern Flexbox with `flex-wrap` is supported
		} else {
			flexibility(document.documentElement);
		}
		
	}

	window.adapt_fontsize = adapt_fontsize;

	// reCAPTCHA onLoadCallback -- scale
	if (typeof recaptchaCallback !== 'undefined') {
		recaptchaCallback = (function() {
			var cachedRecaptchaCallback = recaptchaCallback;

			return function() {
				var result = cachedRecaptchaCallback.apply(this, arguments);
				adapt_fontsize();
				return result;
			}
		})();
	}


	/** IE8 */
	
	if ($body.hasClass('ie8-max')) {
		const $headerBG = $('#header-bg').find('img');

		var headerBGLeft = ((winW - 1440) / 2) + 354;
		function handleHeaderBGLeft() {
			headerBGLeft = ((winW - 1440) / 2) + 354;
			headerBGLeft = Math.max(headerBGLeft, 354);

			$headerBG.css('left', headerBGLeft);
		}

		handleHeaderBGLeft();

		$window.on('resize', () => {
			handleHeaderBGLeft();
		});

	}



	/** Utils */

	$.fn.isInViewport = function() {
		var elementTop = $(this).offset().top;
		var elementBottom = elementTop + $(this).outerHeight();
	  
		var viewportTop = $(window).scrollTop();
		var viewportBottom = viewportTop + $(window).height();
	  
		return elementBottom > viewportTop && elementTop < viewportBottom;
	};

	function getSelectValues(select) {
		var result = [];
		var options = select && select.options;
		var opt;

		for (var i=0, iLen=options.length; i<iLen; i++) {
		opt = options[i];

		if (opt.selected) {
			result.push(opt.value || opt.text);
		}
		}
		return result;
	}

	function setGetParameter(paramName, paramValue) {
		var url = window.location.href;
		var hash = location.hash;
		url = url.replace(hash, '');
		url = url.replace(/page\/(.+?)\//, '');

		if (paramValue instanceof Array) paramValue = paramValue.join('_');

		// url = url.replace('page/', '');

		if (url.indexOf("?") >= 0) {
			var params = url.substring(url.indexOf("?") + 1).split("&");
			var paramFound = false;
			params.forEach(function(param, index) {
				var p = param.split("=");
				if (p[0] == paramName) {
				params[index] = paramName + "=" + paramValue;
				paramFound = true;
				} 
			});
			if (!paramFound) params.push(paramName + "=" + paramValue);
			url = url.substring(0, url.indexOf("?")+1) + params.join("&");
		} else {
			url += "?" + paramName + "=" + paramValue;
		}
		window.location.href = url + hash;
	}
}