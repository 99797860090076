const baseUrl = "/wp-content/themes/palais-megeve/js/lib/";
const asyncScripts = {
    TweenMax: baseUrl + "TweenMax-1.19.0.min.js",
    slick: baseUrl + "slick-1.9.0.min.js",
    tippy: baseUrl + "tippy-2.5.2.min.js",
    select2: baseUrl + "select2-4.0.3.min.js",
    pickadate: baseUrl + "pickadate/picker.date.js",
    rellax: baseUrl + "rellax.min.js",
}

export default function soLoadAsync(src, callback, relative){
    var script = document.createElement('script');
    script.src = asyncScripts[src]; 
    
    if(callback !== null){
        if (script.readyState) { // IE, incl. IE9
            script.onreadystatechange = function() {
                if (script.readyState == "loaded" || script.readyState == "complete") {
                    script.onreadystatechange = null;
                    callback();
                }
            };
        } else {
            script.onload = function() { // Other browsers
                callback();
            };
        }
    }
    document.getElementsByTagName('head')[0].appendChild(script);
}